export const Brands = [
  {
    name: "Rolex",
  },

  {
    name: "Audemars Piguet",
  },
  {
    name: "Baume & Mercier",
  },
  {
    name: "Blancpain",
  },
  {
    name: "Breitling",
  },
  {
    name: "Cartier",
  },
  {
    name: "Franck Muller",
  },
  {
    name: "Girard-Perregaux",
  },
  {
    name: "Hublot",
  },
  {
    name: "IWC",
  },
  {
    name: "Jaeger-LeCoultre",
  },
  {
    name: "Maurice LeCroix",
  },
  {
    name: "Omega",
  },
  {
    name: "Panerai",
  },
  {
    name: "Patek Philippe",
  },
  {
    name: "Piaget",
  },
  {
    name: "Tag-Heuer",
  },
  {
    name: "Ulysse Nardin",
  },
  {
    name: "Zenith",
  },
];
